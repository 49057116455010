<div class="wrapper" *ngIf="show()">

  <mat-form-field [appearance]="appearance()" [hideRequiredMarker]="hideRequired()" [color]="color()"
                  [matTooltip]="tooltip() ?? ''"
                  matTooltipPosition="above" matTooltipTouchGestures="off">

    <mat-label *ngIf="label()">
      {{ label() }}
      <i class="fad fa-question-circle" *ngIf="tooltip()"></i>
    </mat-label>

    <form-core-html-input [placeholder]="placeholder()" [required]="required()" [readonly]="readonly()"
                          [(ngxModel)]="value" [autofocus]="autofocus()" [disabled]="disabled()"
                          [singleLine]="singleLine()" [unity]="unity()" noClickBubble
                          [showError]="showError()" (touched)="markAsTouched()"/>

    <div matSuffix class="fix">
      <ng-content></ng-content>
    </div>

  </mat-form-field>

  <ngx-form-input-errors [canShow]="canShowError()" [errors]="errors()" [warnings]="warnings()"/>
</div>
