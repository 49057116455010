import {
  afterNextRender, booleanAttribute, ChangeDetectionStrategy, Component, effect, ElementRef, forwardRef, inject, input,
  NgZone, signal, Signal, viewChild
} from '@angular/core';
import suneditor from 'suneditor'
import SunEditor from "suneditor/src/lib/core";
import {align, formatBlock, link, list, paragraphStyle} from "suneditor/src/plugins";
import {SunEditorOptions} from "suneditor/src/options";
import {MatFormFieldControl} from "@angular/material/form-field";
import {NgxFormFieldDirective} from "@juulsgaard/ngx-forms";
import {FocusOptions} from "@angular/cdk/a11y";

@Component({
  selector: 'form-core-html-input',
  templateUrl: './core-html-input.component.html',
  styleUrls: ['./core-html-input.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: forwardRef(() => CoreHtmlInputComponent)}],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
  host: {
    '[class.single-line]': 'singleLine()',
    '[class.unity]': 'unity()'
  }
})
export class CoreHtmlInputComponent extends NgxFormFieldDirective<string> {

  editorElement: Signal<ElementRef<HTMLDivElement>> = viewChild.required('editor', {read: ElementRef});

  readonly = input(false, {transform: booleanAttribute});
  autofocus = input(false, {transform: booleanAttribute});
  singleLine = input(false, {transform: booleanAttribute});
  unity = input(false, {transform: booleanAttribute});

  editor = signal<SunEditor|undefined>(undefined);

  private zone = inject(NgZone);

  constructor() {
    super();

    effect(() => {
      this.editor()?.readOnly(this.readonly());
    });

    afterNextRender(() => this.initEditor());
  }

  protected override writeValue(value: string | undefined) {
    this.editor()?.setContents(value ?? '')
  }

  protected override isEmpty(value: string | undefined): boolean {
    return !value || value.length < 1 || value === '<p><br></p>';
  }

  override focus(options?: FocusOptions) {
    this.editor()?.core.focus();
  }

  private initEditor() {

    const editor = suneditor.create(this.editorElement().nativeElement, {
      mode: 'balloon',
      callBackSave: () => this.element.dispatchEvent(new Event("save", { bubbles: true })),
      value: this.value ?? undefined,
      ...(this.singleLine() ? singleLineOptions : this.unity() ? unityOptions : fullOptions),
    });

    this.editor.set(editor);

    editor.onFocus = () => {
      this.zone.run(() => this.onFocus());
    }
    editor.onBlur = () => {
      this.zone.run(() => this.onBlur());
    }

    if (this.autofocus()) editor.core.focus();

    editor.onKeyDown = (e) => {
      const event = e as KeyboardEvent;
      if (event.key !== 'Enter') return
      if (this.singleLine()) {
        e.preventDefault();
        return;
      }

      if (event.shiftKey) {
        e.preventDefault();
        editor?.insertHTML('\n');
      }
    };

    editor.onChange = content => {

      if (this.singleLine()) {
        content = content.replace(/\n|^<p>|<br>|<\/p>$/g, '');
      }

      this.zone.run(() => this.setValue(content));
    };
  }

}

const singleLineTags = 'span|b|i|strong|em|u|strike|s|sup|sub|code|a';
const singleLineTagBlacklist = 'br|p|div|pre|blockquote|h1|h2|h3|h4|h5|h6|ol|ul|li|hr|figure|figcaption|img|iframe|audio|video|table|thead|tbody|tr|th|td|var|ins|del|svg|path|details|summary'

const singleLineOptions: SunEditorOptions = {
  buttonList: [
    [
      'bold',
      'italic'
    ],
    [':t-More Text-default.more_text', 'underline', 'strike', 'subscript', 'superscript', 'removeFormat'],
    [':v-Misc-default.more_vertical', 'undo', 'redo', 'codeView'],
    ['%500', [
      [
        'bold',
        'italic'
      ],
      [':t-More Text-default.more_text', 'underline', 'strike', 'subscript', 'superscript', 'removeFormat'],
      [':v-Misc-default.more_vertical', 'undo', 'redo', 'codeView'],
    ]]
  ],
  pasteTagsWhitelist: singleLineTags,
  tagsBlacklist: singleLineTagBlacklist,
  minHeight: '0px'
};

const fullOptions: SunEditorOptions = {
  plugins: [list, align, paragraphStyle, formatBlock, link],
  buttonList: [
    [
      'bold',
      'italic',
      'link'
    ],
    [':t-More Text-default.more_text', 'underline', 'strike', 'subscript', 'superscript', 'removeFormat'],
    ['list', 'align', 'formatBlock'],
    [':v-Misc-default.more_vertical', 'undo', 'redo', 'codeView'],
    ['%500', [
      [
        'bold',
        'italic'
      ],
      [':t-More Text-default.more_text', 'underline', 'strike', 'link', 'subscript', 'superscript', 'removeFormat'],
      [':p-More Paragraph-default.more_paragraph', 'list', 'align', 'formatBlock'],
      [':v-Misc-default.more_vertical', 'undo', 'redo', 'codeView'],
    ]]
  ],
  minHeight: '40px'
};

const unityOptions: SunEditorOptions = {
  plugins: [list, align, paragraphStyle, formatBlock],
  buttonList: [
    ['bold', 'italic'],
    [':t-More Text-default.more_text', 'underline', 'strike', 'subscript', 'superscript', 'removeFormat'],
    ['list', 'formatBlock'],
    [':v-Misc-default.more_vertical', 'undo', 'redo'],
    ['%500', [
      ['bold', 'italic'],
      [':t-More Text-default.more_text', 'underline', 'strike', 'subscript', 'superscript', 'removeFormat'],
      [':p-More Paragraph-default.more_paragraph', 'list', 'formatBlock'],
      [':v-Misc-default.more_vertical', 'undo', 'redo'],
    ]]
  ],
  formats: ['h1', 'h2', 'h3', 'p'],
  minHeight: '40px'
};
