import {booleanAttribute, ChangeDetectionStrategy, Component, input} from '@angular/core';
import {BaseInputComponent} from "@juulsgaard/ngx-forms";
import {NgIf} from "@angular/common";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatTooltip} from "@angular/material/tooltip";
import {CoreHtmlInputComponent} from "@forms/components/core-html-input/core-html-input.component";
import {NoClickBubbleDirective} from "@juulsgaard/ngx-tools";
import {FormInputErrorsComponent} from "@juulsgaard/ngx-forms-inputs";

@Component({
  selector: 'form-html-input',
  templateUrl: './html-input.component.html',
  styleUrls: ['./html-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatError,
    CoreHtmlInputComponent,
    NgIf,
    MatTooltip,
    NoClickBubbleDirective,
    FormInputErrorsComponent
  ]
})
export class HtmlInputComponent extends BaseInputComponent<string, string> {

  singleLine = input(false, {transform: booleanAttribute});
  unity = input(false, {transform: booleanAttribute});

  constructor() {
    super();
  }

  postprocessValue(value: string | undefined) {
    if (this.singleLine()) return !value ? undefined : value;
    return value === '<p><br></p>' ? undefined : value;
  }

  preprocessValue(value: string | undefined): string {
    return value ?? "";
  }

}

